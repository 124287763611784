import React from 'react'

class RedirectToContentManager extends React.Component {
  componentDidMount() {
    window.location = 'https://content-manager.goji.aero/thales/'
  }

  render() {
    return null
  }
}

export default RedirectToContentManager
